import React, { useEffect, useState, useContext } from "react";
import { Portfolio } from "../components/Portfolio";
import { ProjectContext } from "../context/ProjectsContext";

export const PortfolioContainer = ({ match }) => {
  const [projects, updateProjects] = useState([]);
  const { setProjects } = useContext(ProjectContext);
  const { id } = match.params;

  const checkForDeepLink = () => {
    //check for deeplinking
    const elementOfTheDeepLink = document.getElementById(id);
    if (elementOfTheDeepLink) {
      setTimeout(function () {
        elementOfTheDeepLink.click()
      }, 1000)
    }
  }

  useEffect(() => {
    async function callApi() {
      try {
        const response = await fetch(
          `https://mvscstoragedata155447-prod.s3-eu-west-1.amazonaws.com/monstermusic/53884b8f-4fe5-41b0-936b-1f4f0e24e3c3.json`
          // `http://192.168.43.222:5000/53884b8f-4fe5-41b0-936b-1f4f0e24e3c3.json`
        );
        let json = await response.json();
        const videos = json.showcase.projects.items.sort((a, b) =>
          a.order > b.order ? 1 : -1
        );
        updateProjects(videos);
        setProjects(json.showcase);

        checkForDeepLink()

      } catch (err) {
        console.log({ err });
      }
    }
    callApi()
  }, [setProjects]);

  const parseProjectTitle = (txt = "") => {
    let arr = txt.split("|");
    let title = arr[0].toUpperCase().trim();
    title = title.length > 20 ? title.substring(0, 20) + "..." : title;
    return title;
  };

  const parseProjectDescription = (txt = "") => {
    let arr = txt.split("|");
    let desc =
      arr.length > 0 && typeof arr[1] !== "undefined"
        ? '"' + arr[1].trim() + '"'
        : "";
    return desc;
  };

  return (
    <Portfolio
      projects={projects}
      parseProjectTitle={parseProjectTitle}
      parseProjectDescription={parseProjectDescription}
    />
  );
};

const backgroundVideoList = [
    { name: 'corona.mp4', id: '864376705' },
    { name: 'madrid_turismo.mp4', id: '925495876' },
    { name: 'hw_leptop.mp4', id: '996086177' },
    { name: 'seat.mp4', id: '962853661' },
    { name: 'vivo_x_100.mp4', id: '895449868' },
    { name: 'icyhot.mp4', id: '705190688' },
    { name: 'usnavy.mp4', id: '778392004' },
    { name: 'minutemade.mp4', id: '905322143' },
    { name: 'Porsche.mp4', id: '584619414' },
    { name: 'Bentley.mp4', id: '580631499' },
    { name: 'hw_p60.mp4', id: '820272144' },
    { name: 'yiii.mp4', id: '780461969' },
    { name: 'chevy.mp4', id: '705193038' },
    { name: 'royalenf.mp4', id: '439726322' },
    { name: 'samsung.mp4', id: '780286712' },
    { name: 'talcid.mp4', id: '707861260' },
    { name: 'BMW.mp4', id: '579103458' },
    { name: 'mercedes.mp4', id: '861220867' },
    { name: 'Loreal.mp4', id: '639478662' },
    { name: 'hauwei.mp4', id: '751639097' },
    { name: 'Sprite.mp4', id: '455864857' },
    { name: 'Nissan.mp4', id: '498470734' },
    { name: 'dm.mp4', id: "710077184" }
]
export default backgroundVideoList
